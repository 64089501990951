export const SKILLS = [
  {
    title: "Frontend",
    icon: "./assets/images/frontend-icon.png",
    skills: [
      { skill: "HTML5", percentage: "80%" },
      { skill: "CSS3 (Sass/SCSS)", percentage: "90%" },
      { skill: "JavaScript", percentage: "75%" },
      { skill: "React.js", percentage: "80%" },
    ],
  },
  {
    title: "Backend",
    icon: "./assets/images/backend-icon.png",
    skills: [
      { skill: "Node.js", percentage: "70%" },
      { skill: "Express.js", percentage: "65%" },
    ],
  },
  {
    title: "Tools",
    icon: "./assets/images/tools-icon.png",
    skills: [
      { skill: "Git & GitHub", percentage: "85%" },
      { skill: "Visual Studio Code", percentage: "75%" },
      { skill: "Webpack", percentage: "60%" },
      { skill: "Responsive Design", percentage: "70%" },
    ],
  },
  {
    title: "Soft Skills",
    icon: "./assets/images/soft-skills-icon.png",
    skills: [
      { skill: "Problem-solving", percentage: "80%" },
      { skill: "Collaboration", percentage: "85%" },
      { skill: "Attention to Detail", percentage: "75%" },
    ],
  },
];

export const WORK_EXPERIENCE = [
  {
    title: "Associate Developer at Infosys Ltd.",
    date: "Jun 2022 - Present",
    responsibilities: [
      "Lead the design and implementation of an enhanced Orders page, improving user experience and operational efficiency.",
      "Implemented front-end development using React.js and Tailwind CSS, and integrated REST APIs for effective data handling.",
      "Managed PostgreSQL database operations, systematically organizing request and fulfillment data.",
      "Developed and deployed AWS Lambda functions to optimize data retrieval, contributing to a streamlined vehicle ordering process."
    ],
  },
  {
    title: "Associate Developer at AtQuest Sustainability Solutions pvt.ltd.",
    date: "Feb 2021 - Jul 2022",
    responsibilities: [
      "Engineered and maintained web applications with the MERN stack, ensuring responsive design and optimal performance.",
      "Collaborated with cross-functional teams to produce quality software solutions, maintaining adherence to coding best practices.",
      "Played a key role in the development of four CRM applications, improving company-client relationships and workflow efficiency.",
      "Created a sustainability assessment platform and implemented data visualization with Chart.js and D3.js for effective data presentation.",
    ],
  },
  {
    title: "Software Developer Intern at Ceacs pvt.ltd.",
    date: "Jun 2020 - Oct 2020",
    responsibilities: [
      "Focused on backend development with Django, contributing significantly to module development and application features.",
      "Delivered continuous application enhancements and defect resolution to ensure reliability and efficiency.",
      "Developed a CRM application to improve company workflows and client management processes.",
      "Utilized HTML and Bootstrap for intuitive and responsive front-end development, enhancing user interface design."
    ],
  },
];
